import React from 'react';
import { Typography, Container, Box, Stack } from '@mui/material';
import Navbar from '../components/Navbar';

import { Card, TextField, Button } from '@mui/material';

const ContactPage = () => {

    const handleSubmit = () => {
        console.log('Form submitted');
    };

  return (
    <>
      <Navbar />
      <Container maxWidth="md" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Card sx={{ width: '50%', padding: 4, margin: '0 auto' }}>
          <Typography variant="h4" component="h1" gutterBottom align="center">
            Talk to Us
          </Typography>
          <Box component="form" noValidate autoComplete="off">
            <TextField
              fullWidth
              id="email"
              label="Your Email"
              variant="outlined"
              margin="normal"
            />
            <TextField
              fullWidth
              id="message"
              label="How can we help? Please message us"
              multiline
              rows={4}
              variant="outlined"
              margin="normal"
            />
            <Button
              variant="contained"
              color="primary"
              fullWidth
              sx={{ mt: 2 }}
              onClick={handleSubmit}
            >
              Send
            </Button>
          </Box>
          <Typography variant="subtitle1" gutterBottom align="left" sx={{ mt: 8 }}>
            Business Correspondence
            <Typography >6909 S Holly Circle Suite 350, Centennial CO 80112</Typography>
          </Typography>
        </Card>
      </Container>
    </>
  );
};

export default ContactPage;
