import React from 'react';
import { Typography, Container, Box, Stack, Divider } from '@mui/material';
import Navbar from '../components/Navbar';
// Placeholder imports for client logos
import client1Logo from '../assets/client-logo.png';
import motorola_logo from '../assets/motorola_logo.jpeg';
import neo4j_logo from '../assets/neo4j_logo.png';
import offce_depot_logo from '../assets/office_depot_logo.png'
import amway_logo from '../assets/amway_logo.png'
import merck_logo from '../assets/merck-logo.png'
import home_depot_logo from '../assets/homedepot_logo.jpg'



const AboutPage = () => {
  return (
    <>
      <Navbar />
      <Container maxWidth="md">
        <Box sx={{ my: 4 }}>
          <Typography variant="h4" component="h1" gutterBottom>
            About Evol
          </Typography>
          <Divider sx={{ width: '50%', margin: '20px auto' }} />
          
          <Typography variant="h5" component="h2" gutterBottom>
            Evol is a Productonics AI Labs Product
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            At Produtonics, our mission is to empower enterprises by unlocking the full potential of their data. 
            We specialize in developing AI agents that are not only intelligent but also adaptive—agents that learn, 
            evolve, and grow alongside your business. With nearly two decades of experience in implementing ERP and CRM systems, 
            re-engineering business processes, and managing complex data migrations, we understand the intricacies of enterprise ecosystems.
            With a focus on innovation and customer success, we are committed to helping you 
            navigate the complexities of the digital landscape.
            
            
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            We are building AI agents that evolve with your business needs, learn from your data, and integrate seamlessly into your workflows.
          </Typography>

          <Box sx={{ my: 6 }}>
            <Typography variant="h5" component="h2" gutterBottom>
              Team
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
            Our team comprises AI specialists, data scientists, software engineers, and business strategists who are passionate about pushing the boundaries of what AI can achieve.
            </Typography>
          </Box>

          <Box sx={{ my: 6 }}>
            <Typography variant="h5" component="h2" gutterBottom>
              Clients
            </Typography>
            <Stack 
              direction={{ xs: 'column', sm: 'row' }} 
              spacing={4} 
              justifyContent="center" 
              alignItems="center" 
              sx={{ mb: 4 }}
            >
              {[motorola_logo, neo4j_logo, merck_logo, amway_logo, home_depot_logo, offce_depot_logo].map((logo, index) => (
                <Box key={index} sx={{ width: { xs: '50%', sm: '25%' } }}>
                  <img src={logo} alt={`Client ${index + 1}`} style={{ width: '100%', height: 'auto' }} />
                </Box>
              ))}
            </Stack>
            <Typography variant="body1" sx={{ mb: 2 }}>
              We have had the privilege of working with a diverse range of clients across various industries. 
              Our projects have included developing very large custom analytics solutions using Graph DB, NoSQL DB and data lakes. We have helped enterprise make optimial decisions through 
              process re-engineering. Our AI solutions are focused on creating AI agents for customer service, healthcare and financial reconcilation. 
            </Typography>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default AboutPage;
