import React from 'react';
import { Container, Typography, Box, Stack, Divider } from '@mui/material';
import Navbar from '../components/Navbar';

const PrivacyPage = () => {
  return (
    <div>
        <Navbar />
    <Container maxWidth="md">
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Privacy Policy
        </Typography>
        <Divider sx={{ width: '50%', margin: '20px auto' }} />

        <Typography variant="h5" component="h2" gutterBottom>
          Cookies
        </Typography>
        
        <Typography variant="body1" sx={{ mb: 2 }}>
          If you visit our login page, we will set a temporary cookie to determine if your browser accepts cookies. This cookie contains no personal data and is discarded when you close your browser.
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          When you log in, we will also set up several cookies to save your login information and your screen display choices. Login cookies last for two days, and screen options cookies last for a year. If you select "Remember Me", your login will persist for two weeks. If you log out of your account, the login cookies will be removed.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          Embedded content from other websites
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          Articles on this site may include embedded content (e.g. videos, images, articles, etc.). Embedded content from other websites behaves in the exact same way as if the visitor has visited the other website.
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          These websites may collect data about you, use cookies, embed additional third-party tracking, and monitor your interaction with that embedded content, including tracking your interaction with the embedded content if you have an account and are logged in to that website.
        </Typography>


        <Typography variant="h5" component="h2" gutterBottom>
          What rights you have over your data
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          If you have an account on this site, or have left comments, you can request to receive an exported file of the personal data we hold about you, including any data you have provided to us. You can also request that we erase any personal data we hold about you. This does not include any data we are obliged to keep for administrative, legal, or security purposes.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          We do not share your data with any third party site. 
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          Visitor message may be checked through an automated spam detection service.
        </Typography>
      </Box>
    </Container>
    </div>
  );
};

export default PrivacyPage;



