import React from 'react';
import { AppBar, Toolbar, Button, Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import evol_logo from '../assets/Logo_Primary.png';

const Navbar = () => {
  const navigate = useNavigate();

  const goToAbout = () => {
    navigate('/about');
  };

  const goToContact = () => {
    navigate('/contact');
  };

  return (
    <AppBar position="static" color="transparent" elevation={0}>
      <Toolbar>
        <img 
          src={evol_logo} 
          alt="Company Logo" 
          style={{ width: '100px', cursor: 'pointer' }} 
          onClick={() => navigate('/landingPage')}
        />
        <Box sx={{ flexGrow: 0.9 }} />
        <Button color="inherit" onClick={goToAbout} sx={{ mr: 7 }}><Typography variant="h6" align="center"> About</Typography></Button>
        <Button color="inherit" onClick={goToContact}><Typography variant="h6" align="center"> Contact</Typography></Button>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;