
import React from 'react';
import './App.css';
import LandingPage from './pages/landing';
import AboutPage from './pages/about';
import ContactPage from './pages/contact';
import PrivacyPage from './pages/privacy';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <Routes>
                 
                <Route path="/" element={<Navigate to="/landingPage" />} />
                <Route path="/landingPage" element={<LandingPage />} />
                <Route path="/about" element={<AboutPage />} />
                <Route path="/contact" element={<ContactPage />} />
                <Route path="/privacy" element={<PrivacyPage />} />
      </Routes>
    </div>
  );
}

export default App;
